function getCurrentUser() {
    // return this.$store.state.auth.user;
    return JSON.parse(localStorage.getItem('user'));
}

function getRecaptchaSiteKey() {
  return '6Lf-mj8kAAAAAECHCeTJNq4Jt1tHBluktnpPctTn';
}

function isSessionExpired() {
  let u = getCurrentUser();
  if (!u) {
    return true;
  } else {
    let expirationDate = new Date(u.dateExpirationSession);
    let now = new Date;
    return now > expirationDate;
  }
}
function triUtilisateurAlphabetique(a,b) {
  var textA = a.nom?.toUpperCase()+a.prenom?.toUpperCase();
  var textB = b.nom?.toUpperCase()+b.prenom?.toUpperCase();
  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

function GetDateDesDonnees() {
  if (!localStorage.getItem('user')){
    return null;
  }
  var d = new Date(JSON.parse(localStorage.getItem('user')).dateDesDonnees);
  var mm = d.getMonth() + 1;
  var dd = d.getDate();
  return  [
      (dd>9 ? '' : '0') + dd,
      (mm>9 ? '' : '0') + mm,
      d.getFullYear()
     ].join('/');
}

function getUrlWs () {
    var protocol = window.location.protocol;
    var hostname = window.location.hostname;
  
    if (hostname.toLowerCase() == "localhost") {
      return protocol + "//localhost:5217/api/";
    }
    else if (hostname.toLowerCase() == "weblagardere.dom001.local"){
      return protocol + "//apilagardere.dom001.local/api/"; // URL de test interne
    } 
    else if (hostname.toLowerCase() == "demo.pfv-declarations.fr"){
      return protocol + "//demoapi.pfv-declarations.fr/api/"; // URL de demo externe
    } 
    else if (hostname.toLowerCase() == "pfv-declarations.fr" || hostname.toLowerCase() == "www.pfv-declarations.fr"){
      return protocol + "//api.pfv-declarations.fr/api/"; // URL de prod
    } 
    else {
      return protocol + "//api.pfv-declarations.fr/api/"; 
    }
  }

function getCurrentDateHour() {
  let d = new Date;
  let annee = d.getFullYear();
  let mois = d.getMonth() + 1;
  mois = (mois < 10) ? "0"+mois : mois;
  let jour = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let heure = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  let minute = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  return jour+"."+mois+"."+annee+"-"+heure+"."+minute;
}

function GetDateFormatteeFr(d) {
  if(d==null){
    return "-";
  }
  if(typeof d=="string"){
    d= new Date(d);
  }
  var jj = (d.getDate() < 10) ? "0" : "";
  jj += d.getDate();
  var mm = (d.getMonth() + 1 < 10) ? "0" : "";
  mm += d.getMonth() + 1;
  var yyyy = d.getFullYear();
  return jj + "/" + mm + "/" +yyyy;
}
function GetDateEtHeureFormatteeFr(d) {
  if(typeof d=="string"){
    d= new Date(d);
  }
  if(d==null || d==undefined){
    return "";
  }
  var jj = (d.getDate() < 10) ? "0" : "";
  jj += d.getDate();
  var mm = (d.getMonth() + 1 < 10) ? "0" : "";
  mm += d.getMonth() + 1;
  var yyyy = d.getFullYear();
  var hh = d.getHours();
  var MM = (d.getMinutes() < 10) ? "0"+d.getMinutes() : d.getMinutes() ;
  return jj + "/" + mm + "/" +yyyy+" à "+hh+"h"+MM;
}

function formatJoliNombre(nbr){
  if(nbr==null || nbr==undefined ){
    nbr=0;
  }
  if(typeof nbr=="string"){
    nbr= parseFloat(nbr.replace(",", ".").replace(/[^0-9|.|-]/g,""))
  }  
  if(isNaN(nbr)){
    nbr=0;
  }
  var formatter = Intl.NumberFormat().format(nbr.toFixed(2)).replace(',', '.');

  //pour avoir tjs 2 chiffre après la virgule
  if(!formatter.match(/\.[0-9]{2}/) && formatter.match(/\.[0-9]{1}/)  ){
    formatter +="0";
  }
  else if(formatter.indexOf(".")==-1){
    formatter+=".00";
  }

  return formatter;
}

function nextInput(ev, classe){
  //trouve le prochain input qui a la même classe
  var inputsListe =  Array.prototype.slice.call( document.getElementsByClassName(classe));
  var inputIndex = inputsListe.indexOf(ev.target);
  //déclenche le focus
  var nextInput = inputsListe[inputIndex+1];
  if(nextInput)
      nextInput.focus();
  else //si on n'a pas de suivant, on sort juste du champ actuel
      ev.target.blur();
}

export {
    getCurrentUser,getUrlWs, getCurrentDateHour,isSessionExpired, 
    GetDateFormatteeFr,GetDateEtHeureFormatteeFr, getRecaptchaSiteKey,
    formatJoliNombre,GetDateDesDonnees, nextInput, triUtilisateurAlphabetique
}