<template>
  <Spinner :loading="Loading" />
  <div class="row background-map">
    <div class="col-md-12">
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="logincard">
              <h1>Déclarations annuelles de la PFV</h1>
              <h2>(Participation aux Frais de Vente)</h2>
              <hr />
              <Form @submit="handleLogin" :validation-schema="schema">
                <div class="row marginBottom10px">
                  <div class="col-md-4 text-right"><label for="email">Email : </label></div>
                  <div class="col-md-6">
                    <Field name="email" type="text" class="form-control" />
                    <ErrorMessage name="email" class="error-feedback" />
                  </div>
                </div>
                <div class="row marginBottom10px">
                  <div class="col-md-4 text-right"><label for="password">Mot de passe : </label></div>
                  <div class="col-md-6">
                    <Field name="password" type="password" class="form-control" />
                    <ErrorMessage name="password" class="error-feedback" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 offset-md-4"><button class="btn btn-rose btn-block" :disabled="loading">
                      <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                      <span>SE CONNECTER</span>
                    </button>
                  </div>
                </div>
                <div class="form-group errorMessage">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
              </Form>

              <div class="row marginTop20px liens-en-bas">
                <div class="col-md-12 text-center">
                  <RouterLink to="/premiere-connexion">Première connexion ?</RouterLink> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <RouterLink to="/mot-de-passe-oublie">Mot de passe oublié ?</RouterLink> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <!-- <RouterLink to="/SalarieSorti">Salarié sorti ?</RouterLink> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <Footer />
</template>
  
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Footer from "@/components/Public/Footer.vue";
import Header from "@/components/Public/Header.vue";
import Spinner from "@/components/Spinner.vue";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Header,
    Footer,
    Form,
    Field,
    ErrorMessage,
    Spinner
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("L'email est obligatoire."),
      password: yup.string().required("Le mot de passe est obligatoire."),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      if (this.$store.state.auth && this.$store.state.auth.user && this.$store.state.auth.user.role != "gerant") {
        this.$router.push("/mes-declarations");
      } else {
        this.$router.push("/home");
      }
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          if (this.$store.state.auth && this.$store.state.auth.user && this.$store.state.auth.user.role != "gerant") {
            this.$router.push("/mes-declarations");
          } else {
            this.$router.push("/home");
          }
        },
        (error) => {
          this.loading = false;
          this.message = error.response.data;
          // (error.response &&
          //   error.response.data &&
          //   error.response.data.message) ||
          // error.message ||
          // error.toString();
        }
      );
    },
  },
};
</script>
  
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_public.scss";
.liens-en-bas a {
  font-size: 1em;
}
.errorMessage {
  //height: 40px;
  margin: 20px;
}
</style>