import axios from 'axios';
import { getUrlWs } from "@/utilities";
const API_URL = getUrlWs();
//const API_URL = 'http://localhost:5217/api/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'User/auth/signin', {
        Email: user.email,
        Password: user.password
      })
      .then(response => {
        if (response.data) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      Email: user.email,
      Password: user.password
    });
  }
}

export default new AuthService();