<template>
    <div>
        <div class="footer row">
            <div class="col">
                <p class="text-center">
                    © {{ this.year }} Lagardère Travel Retail France, une branche du groupe Lagardère· Tous droits
                    réservés - <router-link to="/mentions-legales">Mentions légales</router-link> - <router-link
                        to="/donnees-personnelles">Données personnelles et cookies</router-link>
                </p>
            </div>
        </div>

        <div class="col-md-4 col-sm-12 button-fixed" v-if="!CookiesAcceptes" id="cookie-banner">
            <div class="p-3 pb-4 bg-custom text-white">
                <div class="row">
                    <div class="col-10">
                        <h2>Cookies</h2>
                    </div>
                    <!-- <div class="col-2 text-center">
                        <i class="fas fa-times" id="close"></i>
                    </div> -->
                </div>
                <p>Ce site utilise des cookies afin d'améliorer votre
                    expérience et optimiser nos sites et services. Un cookie
                    est un petit fichier de texte transféré à partir d'un serveur web vers votre navigateur web ou votre
                    disque
                    dur lorsque vous visitez un site web.
                </p>
                <button type="button" class="btn btn-danger w-100" @click="setCookie()">Accepter les cookies</button>
            </div>
        </div>
    </div>
</template>
    
<script>
import { useCookies } from "vue3-cookies";

export default {
    name: "Footer",
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    data() {
        return {
            year: "",
            CookiesAcceptes: false
        }
    },
    mounted: function () {
        this.getYear();
        this.CookiesAcceptes = this.cookies.get("CookiesAcceptes");
        // this.CookiesAcceptes = localStorage.getItem("CookiesAcceptes");
    },
    methods: {
        getYear() {
            var aujd = new Date();
            this.year = aujd.getFullYear();
        },
        setCookie() {
            this.CookiesAcceptes = true;
            this.cookies.set("CookiesAcceptes", true);
            //localStorage.setItem("CookiesAcceptes", true);
        },
    }
}

</script>
    
<style lang="scss" scoped>
.footer {
    padding-top: 10px;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 900;
    position: fixed;
    background-color: #3D3D3D;
    color: #FFFFFF;

    a,a:active,a:hover {
        color: #FFFFFF;
    }
}

.button-fixed {
    z-index: 1000;
    bottom: 0;
    position: fixed;
    right: 0;
    border-radius: 4px;
}

.bg-custom {
    background-color: #130f40;
}

.fas {
    cursor: pointer;
    font-size: 24px;
    color: #FFFFFF;
}
</style>