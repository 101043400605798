<template>
  <Navigation pageName="Home" />
  <div class="container main-content">

    <div class="row">
      <div class="col-md-12 text-center" v-if="showBoutonDeclarerPFV">
      </div>
    </div>

    <!-- les deux encarts géranst et PDVs -->
    <div class="row" v-if="currentUser">
      <div class="col-md-5">
        <div class="card card-gris">
          <div class="card-header">
            <h4><span class="rond-bleu initiales">{{ currentUser.prenom.substr(0, 1).toUpperCase() }}{{
              currentUser.nom.substr(0, 1).toUpperCase()
            }}</span>
              <span class="nomprenom">{{ currentUser.prenom }} {{ currentUser.nom }}</span>
              <span class="emploi">{{ currentUser.emploi }}</span>
            </h4>
          </div>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-md-12">
                  <ul class="contacts">
                    <li>N° matricule : {{ currentUser.matriculeGerant }}</li>
                    <li v-if="currentUser.email"><span class="icone-contact"><font-awesome-icon
                          icon="fa-solid fa-envelope" /></span>{{
                            currentUser.email
                          }}</li>
                    <li v-if="currentUser.telephone"><span class="icone-contact"><font-awesome-icon
                          icon="fa-solid fa-phone" /></span>{{
                            currentUser.telephone
                          }}</li>
                    <li v-if="currentUser.mobile"><span class="icone-contact"><font-awesome-icon
                          icon="fa-solid fa-mobile-screen" /></span>
                      {{
                        currentUser.mobile
                      }}</li>
                  </ul>
                </div>
              </div>

              <div class="row" v-if="currentUser.adresse">
                <div class="col-md-12">
                  <ul class="contacts">
                    <li><font-awesome-icon icon="fa-solid fa-location-dot" /> {{ currentUser.adresse }}</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;{{ currentUser.codePostal }} {{ currentUser.ville }}</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="card card-gris">
          <div class="card-header">
            <h4><span class="rond-bleu"><font-awesome-icon icon="shop" /></span> Mes points de vente à déclarer (actif(s)
              en {{ annee - 1 }})</h4>
          </div>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-md-12">
                  <div class="petitpadding">
                    <div id="conteneurtableoverflow">
                      <EasyDataTable v-if="ListePdvActifs && ListePdvActifs.length > 0" :headers="ListePdvActifsHeaders"
                        :items="ListePdvActifs" hide-footer table-class-name="customize-table">
                      </EasyDataTable>
                      <p v-else>Aucun point de vente</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- btn d'accès aux déclarations -->
    <div class="row" v-if="showBoutonDeclarerPFV && ParamCampagne && campagneEnCours"><!--  hasDeclaAnneeSuivante -->

      <!-- déclaration de la campagne en cours -->
      <div :class="hasDeclaAnneeSuivante?(campagneEnCours?'col-7':'col-5'):'col-12'">
        <span v-if="statutDeclaAnneeActuel == 'CONSULTER'" :class="hasDeclaAnneeSuivante?'rond-rose':'rond-rose col-grand'"><font-awesome-icon icon="fa-solid fa-check" size="xl"/></span>
        <div :class="hasDeclaAnneeSuivante?(campagneEnCours?'card cadre-rose p-bottom p-top':'card cadre-gris'):'card cadre-rose'">
          <div class="card-text">
            <div class="text-center">
              <span class="accroche" v-if="hasDeclaAnneeSuivante" >
                Déclaration annuelle de la PFV <br v-if="!campagneEnCours" />
                <span class="font-weight-bold annee">{{ (annee) }} pour l'année {{ annee - 1 }}</span>
                </span>
              <span v-else class="accroche">Déclaration annuelle de la 
                PFV (Participation aux Frais de Vente)
                {{ (annee) }} pour l'année {{ annee - 1 }}</span>                
            </div>
            <div class="col-md-12 text-center">
              <router-link
                v-if="(currentUser && currentUser?.role == 'gerant' && ListePdvActifs.length > 0 && Declarations && Declarations.find(dd => dd.annee == (annee - 1)))"
                :to="'declaration-' + (statutDeclaAnneeActuel == 'CONSULTER' ? 'edit' : 'recap') + '?id=' + (Declarations.find(dd => dd.annee == (annee - 1)).idDeclaration)"
                :class="campagneEnCours || !hasDeclaAnneeSuivante?'btn btn-rose':'btn btn-gris'">
                {{ statutDeclaAnneeActuel }} LA DECLARATION
              </router-link>
            </div>
          </div>
        </div>
      </div>
  
      <!-- bloc pour accéder à la déclaration de l'année en cours (2024 si on est en 2024-> année non cloturée) -->
      <div :class="campagneEnCours?'col-5':'col-7'" v-if="hasDeclaAnneeSuivante">
        <div class="card cadre-bleu" >
          <div class="card-text">
            <div class="text-center">
              <span class="accroche">Déclaration annuelle de la PFV <br v-if="campagneEnCours"/>
                <span class="font-weight-bold annee">{{ (annee+1) }} pour l'année {{ annee }}</span>
              </span>
            </div>
            <div class="col-md-12 text-center">
              <router-link
                v-if="(currentUser && currentUser?.role == 'gerant' )"
                :to="'declaration-recap?id=' + (Declarations.find(dd => dd.annee == (annee)).idDeclaration)"
                class="btn btn-bleu">
                Accéder à la declaration
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="m-4" v-else>
    </div>
    <!-- <div class="d-flex justify-content-center p-3 m-5" v-else>
      <div class="spinner-border TxtBleuLgtrf"></div>
    </div> -->
    
    <!-- historique des déclarations signées-->
    <div class="row" v-if="(showDeclarationHisto || showDocumentsHisto || showBoutonDeclarerPFV)">
      <div v-if="showDeclarationHisto && Declarations.filter(x => x.signeeLe).length > 0" class="col-md-12">
        <div class="card card-gris mb-4">
          <div class="card-header">
            <h4><span class="rond-bleu"><font-awesome-icon icon="fa-solid fa-clock-rotate-left" /></span> Mes déclarations
              PFV</h4>
          </div>
          <div class="card-body">

            <div class="card-text">
              <div class="contenucard col-md-12">
                <EasyDataTable v-if="Declarations && Declarations.length > 0" :headers="TableDeclarationsHeaders"
                  :items="Declarations.filter(x => x.signeeLe)" hide-footer table-class-name="customize-table"
                  id="tableau-declaration" header-text-direction="right" body-text-direction="right">

                  <template #item-pdv="item">
                    <span v-for="(pdv, idxPdv) in item.numPointVente" :key="idxPdv">
                      {{ pdv.numPointVente }}<span v-if="idxPdv != item.numPointVente.length - 1">, </span>
                    </span>
                  </template>

                  <template #item-idDeclaration="item">
                    <router-link :to="'declaration-edit?id=' + item.idDeclaration"
                      v-if="item.idDeclaration > 0 && item.annee != (annee - 1)"><font-awesome-icon
                        icon="fa-solid fa-arrow-right" transform="shrink-7" mask="fa-solid fa-circle" size="2x"
                        class="blue-icon" /></router-link>
                    <router-link :to="'declaration-edit?id=' + item.idDeclaration"
                      v-if="item.idDeclaration > 0 && item.annee == (annee - 1)"><font-awesome-icon
                        icon="fa-solid fa-arrow-right" transform="shrink-7" mask="fa-solid fa-circle" size="2x"
                        class="pink-icon" /></router-link>
                    <router-link :to="'declaration-edit?annee=' + item.annee"
                      v-if="!item.idDeclaration"><font-awesome-icon icon="fa-solid fa-arrow-right" transform="shrink-7"
                        mask="fa-solid fa-circle" size="2x" class="pink-icon" /></router-link>

                  </template>

                  <template #item-depensesEngagees="item">
                    {{ formatJoliNombre(item.depensesEngagees) }}€
                  </template>


                  <template #item-sommesAllouees="item">
                    {{ formatJoliNombre(item.sommesAllouee) }}€
                  </template>
                  <template #item-provisionsCp="item">
                    {{ formatJoliNombre(item.provisionsCp) }}€
                  </template>
                  <!-- <template #header-provisionsCp="header">
                    <div class="text-right">
                      {{ header.text }}
                    </div>
                  </template> -->

                  <template #item-ecarts="item">
                    {{ formatJoliNombre(Math.round((item.sommesAllouees - item.depensesEngagees) * 100) / 100) }}€
                  </template>

                  <template #item-signeeLe="item">
                    {{ GetDateFormatteeFr(item.signeeLe) == "-" ? "À déclarer" : GetDateFormatteeFr(item.signeeLe) }}
                  </template>


                  <template #item-pfvnonjustifie="item">
                    <div class="text-right">
                      <!-- SoldeNPrec + écart - provisionsCp -->
                      {{ (Math.round((item.soldeNPrec + item.sommesAllouees - item.depensesEngagees - item.provisionsCp) *
                        100) / 100).toFixed(2) }}€
                    </div>
                  </template>

                </EasyDataTable>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- bloc infos, tutos, vidéos -->
    <div class="card card-gris asmodeus">
      <div class="card-body">
        <div class="card-text">
          <div class="row">
            <div class="col-md-6">
              <div class="petitpadding">
                <h5 class="presentation">Bienvenue sur la nouvelle plateforme de déclaration annuelle de la PFV</h5>
                <p>Nous vous invitons à visionner la vidéo de présentation afin d'explorer en détail le mode de fonctionnement de la nouvelle plateforme.</p>
                <p>Nous vous remercions pour votre collaboration.</p>
              </div>
            </div>
            <div class="col-md-6">
              <img src="@/assets/imgs/vignette-video-home-gerant.png" class="img-fluid clickable" @click="ShowModalVideoGerant=true"/>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-if="ListePdvActifs?.length > 0">
      <vue-final-modal v-model="ShowModalPremiereConnexion" classes="modal-container" content-class="modal-content large"
        :click-to-close="false">
        <div class="modal-header modal-header-rose">
          <button class="modal__close" @click="FermeLaModalePremiereConnexion()">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
          <span class="modal__title">NOUVELLE PLATEFORME DE DÉCLARATION ANNUELLE DE LA PFV</span>
        </div>
        <div class="modal-text" v-if="PdvSpv.length > 0 || PdvSansSpv.length > 0">

          <div v-if="PdvSpv.length > 0 && PdvSansSpv.length == 0">
            <p>Nous avons le plaisir de vous informer que nous avons simplifié le processus de déclaration de la PFV
              annuelle.<br />
              Désormais, vous bénéficiez d'une plateforme unique pour déclarer et justifier vos dépenses annuelles.</p>

            <p>Votre déclaration est automatiquement pré-remplie si vous êtes adhérent au service de paie SPV.</p>

            <p>Ainsi, vous n'avez plus qu'à vérifier les informations, justifier vos dépenses éventuelles et signer votre
              déclaration. <br />
              Nous nous occupons ensuite de la transmission à notre service RH.</p>

            <p><strong>Vos points de vente dont les données sont pré-remplies :</strong>
            <ul>
              <li v-for="p in PdvSpv" :key="p.numPointVente">{{ p.numPointVente }} - {{ p.nom }}</li>
            </ul>
            </p>
          </div>
          <div v-if="PdvSpv.length == 0 && PdvSansSpv.length > 0">
            <p>Nous avons le plaisir de vous informer que nous avons simplifié le processus de déclaration de la PFV
              annuelle.<br />
              Désormais, vous bénéficiez d'une plateforme unique pour déclarer et justifier vos dépenses annuelles.</p>
            <p>Ainsi, vous devez remplir, vérifier et justifier vos dépenses annuelles. <br />
              Suite à la signature de votre déclaration, celle-ci sera alors automatiquement transmise à notre service RH.
            </p>

            <p><strong>Vos points de vente dont les données sont à saisir :</strong>
            <ul>
              <li v-for="p in PdvSansSpv" :key="p.numPointVente">{{ p.numPointVente }} - {{ p.nom }}</li>
            </ul>
            </p>
          </div>
          <div v-if="PdvSpv.length > 0 && PdvSansSpv.length > 0">
            <p>Nous avons le plaisir de vous informer que nous avons simplifié le processus de déclaration de la PFV
              annuelle.<br />
              Désormais, vous bénéficiez d'une plateforme unique pour déclarer et justifier vos dépenses annuelles.</p>
            <p>Votre déclaration est automatiquement pré-remplie si vous êtes adhérent au service de paie SPV.<br />
              Nous tenons à vous rassurer quant à la confidentialité de vos données, seuls les totaux (données agrégées)
              sont importés automatiquement.</p>
            <p>Ainsi, vous n'avez plus qu'à vérifier les informations, justifier vos dépenses éventuelles et signer votre
              déclaration. <br />
              Nous nous occupons ensuite de la transmission à notre service RH.</p>
            <p>Pour vos points de vente pour lesquels vous n'adhérez pas au service paie SPV, vous devrez saisir
              l'ensemble
              des informations demandées.</p>

            <p><strong>Vos points de vente dont les données sont pré-remplies (Adhérent service paie SPV) :</strong>
            <ul>
              <li v-for="p in PdvSpv" :key="p.numPointVente">{{ p.numPointVente }} - {{ p.nom }}</li>
            </ul>
            </p>


            <p><strong>Vos points de vente dont les informations sont à saisir :</strong>
            <ul>
              <li v-for="p in PdvSansSpv" :key="p.numPointVente">{{ p.numPointVente }} - {{ p.nom }}</li>
            </ul>
            </p>

          </div>

          <p class="text-right"><strong>Merci pour votre collaboration.<br />Lagardère Travel Retail France</strong></p>
          <label for="NePlusAfficher"><input type="checkbox" v-model="NePlusAfficher" id="NePlusAfficher" />Ne plus
            afficher
            à la prochaine connexion</label>

        </div>

      </vue-final-modal>
    </div>

    <vue-final-modal v-model="ShowModalVideoGerant" classes="modal-container" content-class="modal-content large"
      @opened="playVideo()" @closed="stopVideo()">
      <div class="modal-header">
        <button class="modal__close" @click="ShowModalVideoGerant = false">
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </button>
        <span class="modal__title">Présentation de la plateforme</span>
      </div>
      <div class="modal-text">
        <div class="row">
          <div class="col-md-12 text-center">
            <video class="videoPresentationhome" controls>
              <source
                src="https://www.pfv-declarations.fr/videos/LTRF_PFV_Presentation_Gerant_nouvelle_plateforme_declaration_PFV.mp4"
                type=video/mp4>
            </video>
          </div>
        </div>
      </div>
    </vue-final-modal>


  </div>
</template>
  
<script>
import { useCookies } from "vue3-cookies";
import Navigation from "@/components/Nav.vue";
import { getUrlWs, isSessionExpired, GetDateFormatteeFr, formatJoliNombre } from "@/utilities";
import authHeader from '@/services/auth-header';
import { date } from "yup";

export default {
  name: "Home",
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      ShowModalPremiereConnexion: false,
      ShowModalVideoGerant:false,
      NePlusAfficher: false,
      annee: "",
      ParamCampagne: null,
      Declarations: [],
      PdvSpv: [],
      PdvSansSpv: [],
      TableDeclarationsHeaders: [
        {
          value: 'annee',
          text: 'Année déclarée',
          sortable: false
        },
        // {
        //   value: 'matriculeGerant',
        //   text: 'Identifiant',
        //   sortable: false
        // },
        {
          value: 'pdv',
          text: 'Identifiant',
          sortable: false
        },
        {
          value: 'depensesEngagees',
          text: 'Depenses Engagées',
          sortable: false
        },
        {
          value: 'sommesAllouees',
          text: 'Sommes Allouées',
          sortable: false
        },
        {
          value: 'ecarts',
          text: 'Ecarts',
          sortable: false
        },
        {
          value: 'provisionsCp',
          text: 'Provisions CP',
          sortable: false
        },
        {
          value: 'pfvnonjustifie',
          text: 'PFV non justifiée',
          sortable: false
        },
        {
          value: 'signeeLe',
          text: 'Signée le',
          sortable: false
        },

        {
          value: 'idDeclaration',
          text: '',
          sortable: false
        }

      ],
      ListePdvActifsAnneeSuivante:[],
      ListePdvActifs: [],
      ListePdvActifsHeaders: [
        {
          value: 'numPointVente',
          text: 'Identifiant',
          sortable: true
        },
        {
          value: 'nom',
          text: 'Libellé',
          sortable: true
        },
        // {
        //   value: 'siret',
        //   text: 'Siret',
        //   sortable: true
        // },
        // {
        //   value: 'ville',
        //   text: 'Ville',
        //   sortable: true
        // },
      ]
    };
  },
  mounted: function () {
    this.GetSpvNonSpvMixte();
    //this.GestionPopPremiereConnexion();
    this.ParamCampagneAnnee();
  },
  components: {
    Navigation
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showBoutonDeclarerPFV() {
      if (this.currentUser && this.currentUser.listeModule) {
        return this.currentUser.listeModule.includes("declarerPFV");
      }
      return false;
    },
    showDeclarationHisto() {
      if (this.currentUser && this.currentUser.listeModule) {
        return this.currentUser.listeModule.includes("declarationsListe");
      }
      return false;
    },
    showDocumentsHisto() {
      if (this.currentUser && this.currentUser.listeModule) {
        return this.currentUser.listeModule.includes("mesDocuments");
      }
      return false;
    },
    statutDeclaAnneeActuel() {
      var declaAnnee = this.Declarations.find(d => d.annee == this.annee - 1);
      if (declaAnnee) {
        if (declaAnnee.signeeLe != null) {
          return "CONSULTER";
        }
        if (declaAnnee.datePremiereConsultation == null) {
          return "COMMENCER";
        }
        else {
          return "POURSUIVRE";
        }
      } else {
        return "COMMENCER"
      }
    },
    hasDeclaAnneeSuivante(){
      return false;
      //pour quand on donnera accès à N+1
      //return this.ListePdvActifsAnneeSuivante.length > 0 && this.Declarations && this.Declarations.find(dd => dd.annee == (this.annee));

    },
    campagneEnCours(){
      var listeAccesAvantPremiere = [
        //spv
        "j.boutigny@lagardere-tr.fr",
        "a.leidecker@lagardere-tr.fr",
        "j.aubry@lagardere-tr.fr",
        "m.ndiaye@lagardere-tr.fr",
        "d.honca@lagardere-tr.fr",
        //non spv
        "d.cataldo@lagardere-tr.fr",
        "m.velimirovic@lagardere-tr.fr",
        "f.noel@lagardere-tr.fr"
      ];
      if (this.currentUser && this.currentUser.listeModule && listeAccesAvantPremiere.indexOf(this.currentUser.email)!=-1) {
        return this.currentUser.listeModule.includes("declarerPFV");
      }


      var today = new Date();

      var dateLimiteDepotSupp = new Date(this.ParamCampagne.dateLimiteDepotSupp);
      var dateDebutCampagne = new Date(this.ParamCampagne.dateDebutCampagne);

      // On regarde si la date d'aujourd'hui est comprise entre la dateLimiteDepotSupp et la date début de la campagne 
      if(today <= dateLimiteDepotSupp && today >= dateDebutCampagne){
        // Alors il y'a une campagne en cours
        return true;
      }

      return false 
    }
  },

  methods: {
    formatJoliNombre,
    GetDateFormatteeFr,
    playVideo() {
      document.getElementsByClassName('videoPresentationhome')[0].play();
    },
    stopVideo() {
      document.getElementsByClassName('videoPresentationhome')[0].pause();
    },
    GestionPopPremiereConnexion() {
      if (this.cookies.get("ModalPremiereConnexion") != "non" && !this.$store.state.auth.user.popDejaAffichee) {
        this.$store.state.auth.user.popDejaAffichee = true;
        this.ShowModalPremiereConnexion = true;
      }
    },
    GetDecla() {
      this.$http
        .post(getUrlWs() + 'Declaration/LoadDeclarationsGerantListe', {}, { headers: authHeader() })
        .then(response => {
          this.Declarations = response.data.slice(0, 3);//les 3 premières
        }).catch((err) => {

          if (!isSessionExpired()) {
            var msgErr = 'Impossible de charger la liste des déclarations';
            if (err.response && err.response.data) {
              msgErr = err.response.data;
            }
            //this.$toast.error(msgErr);
          }
        });
    },
    GetSpvNonSpvMixte() {
      this.$http
        .get(getUrlWs() + 'Pdv/GetListePdvAvecCodeSpv', { headers: authHeader() })
        .then(response => {
          this.PdvSpv = response.data.listePdvAvecCodeSpv;
          this.PdvSansSpv = response.data.listePdvSansSpv;
        }).catch((err) => {

        });

    },
    GetPdvActifs(annee) {
      return new Promise((resolve, reject)=>{
        this.$http
          .get(getUrlWs() + 'Pdv/GetPdvActifs/' + annee, { headers: authHeader() })
          .then(response => {
            resolve(response.data);            
          }).catch((err) => {
            if (!isSessionExpired()) {
              var msgErr = 'Impossible de charger les PDV';
              if (err.response && err.response.data) {
                msgErr = err.response.data;
              }
              //this.$toast.error(msgErr);
            }
            reject(err);
          });
      })
    },
    FermeLaModalePremiereConnexion() {
      this.ShowModalPremiereConnexion = false;
      if (this.NePlusAfficher) {
        this.cookies.set("ModalPremiereConnexion", "non");
      }
    },
    ParamCampagneAnnee(){
      this.$http
        .get(getUrlWs() + 'Relance/GetParamCampagne/' + (this.annee - 1), { headers: authHeader() })
        .then(response => {
          this.ParamCampagne = response.data;
        }).catch((err) => {
        });
    }
  },
  created() {
    //récup l'année actuelle
    this.annee = new Date(Date.now()).getFullYear();
    //récup les declarations (si l'utilisateur a les droits sur les déclarations)
    if (this.currentUser && this.currentUser.listeModule && this.currentUser.listeModule.includes("declarationsListe")) {
      
      //liste des déclarations du gérant
      this.GetDecla();
      
      //pdvs actifs pour l'année N
      this.GetPdvActifs(this.annee).then(function(pdvactifs){
        this.ListePdvActifs = pdvactifs;
      }.bind(this));
      //pdvs actifs pour l'année N+1
      this.GetPdvActifs(this.annee+1).then(function(pdvactifs){
        this.ListePdvActifsAnneeSuivante = pdvactifs;
      }.bind(this));
   
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.modal-content {
  .modal-header-rose {
    background-color: $rose !important;
    text-align: center !important;

    span.modal__title {
      display: block;
      width: 100%;
    }
  }

  .modal-text {
    padding: 1.8em !important;
    text-align: justify;
  }

  .text-right {
    text-align: right !important;
    margin-right: 4em;
  }


  input[type=checkbox],
  input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    margin-right: 10px;
  }
}

h4 {
  font-weight: 600;

  .emploi {
    line-height: 0.4;
    font-weight: 400;
    display: block;
    margin-left: 50px;
    font-size: 0.6em;
    color: #868686;
    margin-top: -10px;
    padding-bottom: 5px;
  }
}

.card-body {
  padding-left: 0;
  padding-right: 0;
}

.vue3-easy-data-table {
  border: none !important;
}

.contenucard {
  height: 160px;
}

.card-bleu {
  margin-bottom: 20px;
}

#app {
  #tableau-declaration.vue3-easy-data-table__header {
    th {
      span.direction-left {
        justify-content: initial !important;
      }
    }
  }

  .cadre-rose {
    border: 1px solid $rose;
    color: $rose;
    padding: 15px 5px;
    margin-bottom: 30px;
    margin-top: 30px;

    .btn-rose {
      margin: auto;
      padding: 10px 15px;
      font-size: 1.0rem;
      line-height: 1.0rem;
      font-weight: 600;
      margin-top: 10px;
    }

    .text-centre {
      line-height: 40px;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .cadre-gris {
    border: 1px solid $gris;
    color: $gris;
    padding: 15px 5px;
    margin-bottom: 30px;
    margin-top: 30px;

    .btn-gris {
      margin: auto;
      padding: 10px 15px;
      font-size: 1.0rem;
      line-height: 1.0rem;
      font-weight: 600;
      margin-top: 10px;
    }

    .text-centre {
      line-height: 40px;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }


  .cadre-bleu {
    border: 1px solid $bleu-nuit;
    color: $bleu-nuit;
    padding: 28px 5px;
    margin-bottom: 30px;
    margin-top: 30px;

    .btn-bleu {
      margin: auto;
      padding: 10px 15px;
      font-size: 1.0rem;
      line-height: 1.0rem;
      font-weight: 600;
      margin-top: 10px;
      text-transform: uppercase;
    }

    .text-centre {
      line-height: 40px;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .annee {
    font-size: 1.1em;
  }

  .p-bottom {
    padding-bottom: 42px;
  }

  .p-top {
    padding-top: 42px;
  }
  
}

.initiales {
  vertical-align: top;
}

.rond-rose {
  position: absolute;
  z-index: 1;
  background-color: $rose;
  display: inline-block;
  color: #FFFFFF;
  line-height: 40px;
  width: 40px;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  margin-right: 10px;
  margin-left: 5px;
  left: 90%;
  top: 10%;
}

.col-grand{
  left: 96% !important;
}

.asmodeus {
  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

ul.contacts {
  padding: 0 0 0 20px;

  li {
    list-style-type: none;
    padding-bottom: 5px;
    font-size: 0.8rem;

    .icone-contact {
      width: 25px;
      display: inline-block;
    }
  }
}

#conteneurtableoverflow {
  max-height: 150px;
  overflow-y: auto;
}

.accroche {
  font-weight: 400;
  font-size: 1.2rem;
}

.petitpadding {
  padding: 0 10px;
}

video {
    width: 100%;
    height: auto;
}
h5.presentation {
  margin-top: 10px;
  font-weight: bold;
}
</style>