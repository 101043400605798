import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";

// lazy-loaded
const BoardAdmin = () => import("@/components/BoardAdmin.vue")
const DonneesPersonnelles = () => import("@/components/Public/DonneesPersonnelles.vue");
const MentionsLegales = () => import("@/components/Public/MentionsLegales.vue");
const FinaliserActivation = () => import("@/components/Public/FinaliserActivation.vue");
const MotDePasseOublie = () => import("@/components/Public/MotDePasseOublie.vue");
const ModifierMDP = () => import("@/components/Public/ModifierMDP.vue");
const PremiereConnexion = () => import("@/components/Public/PremiereConnexion.vue");
const SalarieSorti = () => import("@/components/Public/SalarieSorti.vue");
// const BoardModerator = () => import("@/components/BoardModerator.vue")
const DeclaListe = () => import("@/components/declarations/declarations-liste.vue")
const DeclaEdit = () => import("@/components/declarations/declaration-edit.vue")
const DeclaRecap = () => import("@/components/declarations/declaration-recap.vue")
const DocumentsListe = () => import("@/components/documents/documents-liste.vue")
const PdvListe = () => import("@/components/pdv/pdv-liste.vue")
const PdvFiche = () => import("@/components/pdv/pdv-fiche.vue")
const GerantListe = () => import("@/components/gerants/gerant-liste.vue")
const GerantFiche = () => import("@/components/gerants/gerant-fiche.vue")
const Exports = () => import("@/components/Exports.vue")
const Relances = () => import("@/components/Relances.vue")
const Utilisateurs = () => import("@/components/utilisateur/Utilisateurs.vue")
const Profile = () => import("@/components/utilisateur/Profile.vue")
const HomeAdmin = () => import("@/components/Home-admin.vue")


const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/home-admin",
    component: HomeAdmin,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/SalarieSorti",
    component: SalarieSorti,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/pdv-fiche",
    name: "pdvFiche",
    component: PdvFiche,
  },
  {
    path: "/donnees-personnelles",
    name: "DonneesPersonnelles",
    // lazy-loaded
    component: DonneesPersonnelles,
  },
  {
    path: "/mentions-legales",
    name: "MentionsLegales",
    // lazy-loaded
    component: MentionsLegales,
  },
  {
    path: "/mot-de-passe-oublie",
    name: "MotDePasseOublie",
    // lazy-loaded
    component: MotDePasseOublie,
  },
  {
    path: "/ModifierMDP",
    name: "ModifierMDP",
    // lazy-loaded
    component: ModifierMDP,
  },
  {
    path: "/FinaliserActivation",
    name: "FinaliserActivation",
    // lazy-loaded
    component: FinaliserActivation,
  },
  {
    path: "/premiere-connexion",
    name: "PremiereConnexion",
    // lazy-loaded
    component: PremiereConnexion,
  },
  // {
  //   path: "/mod",
  //   name: "moderator",
  //   // lazy-loaded
  //   component: BoardModerator,
  // },
  {
    path: "/mes-declarations",
    name: "mes-declarations",
    // lazy-loaded
    component: DeclaListe,
  },
  {
    path: "/mes-documents",
    name: "mes-documents",
    // lazy-loaded
    component: DocumentsListe,
  },
  {
    path: "/pdv-liste",
    name: "pdv-liste",
    // lazy-loaded
    component: PdvListe,
  },
  {
    path: "/gerant-liste",
    name: "gerant-liste",
    // lazy-loaded
    component: GerantListe,
  },
  {
    path: "/gerant-fiche",
    name: "gerant-fiche",
    // lazy-loaded
    component: GerantFiche,
  },
  {
    path: "/declaration-edit",
    name: "declaration-edit",
    // lazy-loaded
    component: DeclaEdit,
  },
  {
    path: "/declaration-recap",
    name: "declaration-recap",
    // lazy-loaded
    component: DeclaRecap,
  },
  {
    path: "/exports",
    name: "Exports",
    // lazy-loaded
    component: Exports,
  },
  {
    path: "/relances",
    name: "Relances",
    // lazy-loaded
    component: Relances,
  },
  {
    path: "/utilisateurs",
    name: "utilisateurs",
    // lazy-loaded
    component: Utilisateurs,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/SalarieSorti', '/register', '/donnees-personnelles','/mentions-legales','/mot-de-passe-oublie','/premiere-connexion','/ModifierMDP/','/FinaliserActivation/'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    window.document.title = to.meta && to.meta.title ? to.meta.title : 'Lagardère Travel Retail';
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/login');
    } else {
      next();
    }
  });
  
export default router;