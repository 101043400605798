<template>
    <div class="row header">
        <div class="col-md-12">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <router-link to="/"><img src="@/assets/imgs/logo-lagardere.svg" fluid class="logospv" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
};
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.header {
    padding: 20px 0;
    -webkit-box-shadow: 0 7px 9px -7px rgba(0,0,0,0.4);
    box-shadow: 0 7px 9px -7px rgba(0,0,0,0.4);
    background-color: #FFF;
}
</style>
